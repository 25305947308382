import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import { createBrowserHistory } from 'history';
import './App.scss';
import configureStore from './store';
import ProtectedRoute from './containers/ProtectedRoute';
import Root from './containers/Root';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Login'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Register'),
  loading
});

const ChangePassword = Loadable({
  loader: () => import('./views/Password/ChangePassword'),
  loading
});

const ForgotPassword = Loadable({
  loader: () => import('./views/Password/ForgotPassword'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Errors/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Errors/Page500'),
  loading
});

const history = createBrowserHistory();
const store = configureStore();

class App extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Root>
            <Switch>
              <Route exact path="/login" name="Login Page" component={Login} />
              <Route
                exact
                path="/register"
                name="Register Page"
                component={Register}
              />
              <Route
                exact
                path="/forgot-password"
                name="Forgot Pasword"
                component={ForgotPassword}
              />
              <Route
                exact
                path="/change-password"
                name="Change Pasword"
                component={ChangePassword}
              />
              <Route exact path="/404" name="Page 404" component={Page404} />
              <Route exact path="/500" name="Page 500" component={Page500} />
              <ProtectedRoute path="/" name="Home" component={DefaultLayout} />
            </Switch>
          </Root>
        </Router>
      </Provider>
    );
  }
}

export default App;
