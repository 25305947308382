import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import axios from 'axios';

import { ENDPOINT_TYPE } from '../store/constants';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_AMPLIFY_AUTH_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_AUTH_APP_CLIENT_ID
  }
});

const endpointUrl = (type, url) =>
  [
    process.env.REACT_APP_API_URL,
    process.env.REACT_APP_API_VERSION,
    type,
    url
  ].join('/');

// const publicEndpointUrl = url => endpointUrl(ENDPOINT_TYPE.PUBLIC, url);
const authEndpointUrl = url => endpointUrl(ENDPOINT_TYPE.AUTH, url);

// Add auth credentials to all outgoing API requests.
axios.interceptors.request.use(
  async config => {
    const session = await Auth.currentSession();
    // eslint-disable-next-line no-param-reassign
    config.headers.common.authorization = session.idToken.jwtToken;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default {
  auth: {
    signIn: credentials => {
      const { identity: username, password } = credentials;
      return Auth.signIn(username, password);
    },

    signUp: params => {
      const { email, password, username, ...signUpParams } = params;
      return Auth.signUp({
        username,
        password,
        attributes: { email, ...signUpParams }
      });
    },

    completeNewPassword: (user, password) =>
      Auth.completeNewPassword(user, password),

    currentSession: () => Auth.currentSession(),

    currentUser: () => Auth.currentAuthenticatedUser(),

    logout: () => Auth.signOut(),

    confirmSignUp: (username, code) =>
      Auth.confirmSignUp(username, code, { forceAliasCreation: true }),

    forgotPassword: username => Auth.forgotPassword(username),

    forgotPasswordConfirm: params => {
      const { username, code, newPassword } = params;
      return Auth.forgotPasswordSubmit(username, code, newPassword);
    },
    changePassword: (user, oldPassword, newPassword) =>
      Auth.changePassword(user, oldPassword, newPassword),

    updateAuthAttributes: (user, attributes) =>
      Auth.updateUserAttributes(user, attributes)
  },
  user: {
    find: params =>
      axios
        .get(authEndpointUrl(`organizations/groups/users`), { params })
        .then(response => ({ response: response.data, params })),
    findInGroup: (id, params) =>
      axios
        .get(authEndpointUrl(`organizations/groups/${id}/users`), { params })
        .then(response => ({ response: response.data, params })),
    findById: id =>
      axios
        .get(authEndpointUrl(`organizations/groups/users/${id}`))
        .then(response => response.data),
    create: params =>
      axios
        .post(authEndpointUrl('organizations/groups/users'), params)
        .then(response => response.data),
    update: (id, params) =>
      axios
        .patch(authEndpointUrl(`organizations/groups/users/${id}`), params)
        .then(response => response.data),
    delete: id =>
      axios
        .delete(authEndpointUrl(`organizations/groups/users/${id}`))
        .then(response => response.data)
  },
  organizations: {
    find: params =>
      axios
        .get(authEndpointUrl('organizations'), { params })
        .then(response => ({ response: response.data, params })),
    findById: id =>
      axios
        .get(authEndpointUrl(`organizations/${id}`))
        .then(response => response.data),
    create: params =>
      axios
        .post(authEndpointUrl('organizations'), params)
        .then(response => response.data),
    update: (id, params) =>
      axios
        .patch(authEndpointUrl(`organizations/${id}`), params)
        .then(response => response.data),
    delete: id =>
      axios
        .delete(authEndpointUrl(`organizations/${id}`))
        .then(response => response.data)
  },
  groups: {
    find: params =>
      axios
        .get(authEndpointUrl(`organizations/groups`), { params })
        .then(response => ({ response: response.data, params })),
    findInOrganization: (id, params) =>
      axios
        .get(authEndpointUrl(`organizations/${id}/groups`), { params })
        .then(response => ({ response: response.data, params })),
    findById: id =>
      axios
        .get(authEndpointUrl(`organizations/groups/${id}`))
        .then(response => response.data),
    create: (id, params) =>
      axios
        .post(authEndpointUrl(`organizations/${id}/groups`), params)
        .then(response => response.data),
    update: (id, params) =>
      axios
        .patch(authEndpointUrl(`organizations/groups/${id}`), params)
        .then(response => response.data),
    delete: id =>
      axios
        .delete(authEndpointUrl(`organizations/groups/${id}`))
        .then(response => response.data)
  },
  enrollments: {
    find: params =>
      axios
        .get(authEndpointUrl(`enrollments`), { params })
        .then(response => response.data),
    findById: id =>
      axios
        .get(authEndpointUrl(`enrollments/${id}`))
        .then(response => response.data),
    create: params =>
      axios
        .post(authEndpointUrl('enrollments'), params)
        .then(response => response.data),
    update: (id, params) =>
      axios
        .put(authEndpointUrl(`enrollments/${id}`), params)
        .then(response => response.data),
    delete: id =>
      axios
        .delete(authEndpointUrl(`enrollments/${id}`))
        .then(response => response.data)
  },
  licenses: {
    find: params =>
      axios
        .get(authEndpointUrl(`licenses`), { params })
        .then(response => response.data),
    findById: id =>
      axios
        .get(authEndpointUrl(`licenses/${id}`))
        .then(response => response.data),
    create: (id, params) =>
      axios
        .post(authEndpointUrl(`organizations/groups/${id}/licenses`), params)
        .then(response => response.data),
    update: (groupId, id, params) =>
      axios
        .put(
          authEndpointUrl(`organizations/groups/${groupId}/licenses/${id}`),
          params
        )
        .then(response => response.data),
    delete: (groupId, id) =>
      axios
        .delete(
          authEndpointUrl(`organizations/groups/${groupId}/licenses/${id}`)
        )
        .then(response => response.data)
  },
  bundles: {
    find: params =>
      axios
        .get(authEndpointUrl(`bundles`), { params })
        .then(response => ({ response: response.data, params })),
    findById: id =>
      axios
        .get(authEndpointUrl(`bundles/${id}`))
        .then(response => response.data),
    findInGroup: (id, params) =>
      axios
        .get(authEndpointUrl(`organizations/groups/${id}/bundles`), {
          params
        })
        .then(response => ({ response: response.data, params })),
    create: params =>
      axios
        .post(authEndpointUrl('bundles'), params)
        .then(response => response.data),
    update: (id, params) =>
      axios
        .put(authEndpointUrl(`bundles/${id}`), params)
        .then(response => response.data),
    delete: id =>
      axios
        .delete(authEndpointUrl(`bundles/${id}`))
        .then(response => response.data)
  },
  courses: {
    find: params =>
      axios
        .get(authEndpointUrl(`courses`), { params })
        .then(response => ({ response: response.data, params })),
    findById: id =>
      axios
        .get(authEndpointUrl(`courses/${id}`))
        .then(response => response.data),
    findInGroup: (id, params) =>
      axios
        .get(authEndpointUrl(`organizations/groups/${id}/courses`), {
          params
        })
        .then(response => ({ response: response.data, params })),
    create: params =>
      axios
        .post(authEndpointUrl('courses'), params)
        .then(response => response.data),
    update: (id, params) =>
      axios
        .put(authEndpointUrl(`courses/${id}`), params)
        .then(response => response.data),
    delete: id =>
      axios
        .delete(authEndpointUrl(`courses/${id}`))
        .then(response => response.data)
  },
  migrationStatus: {
    find: email =>
      axios
        .get(authEndpointUrl(`users/migration-status/${email}`))
        .then(response => response.data),
    update: (email, params) =>
      axios
        .post(authEndpointUrl(`users/migration-status/${email}`), params)
        .then(response => response.data)
  },
  reports: {
    findOrganizationUserReportById: id =>
      axios
        .get(authEndpointUrl(`reports/organization-user-summary/${id}`))
        .then(response => response.data),
    findUserLessonActivityLogReportsById: (id, params) =>
      axios
        .get(authEndpointUrl(`reports/user-lesson-activity-log/${id}`), {
          params
        })
        .then(response => ({ response: response.data, params }))
  },
  uploads: {
    surveys: params =>
      axios
        .post(authEndpointUrl('uploads/surveys'), params)
        .then(response => response.data)
  }
};
