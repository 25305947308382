export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_COMPLETE_NEW_PASSWORD = 'AUTH_COMPLETE_NEW_PASSWORD';
export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const AUTH_SIGN_UP_CONFIRM = 'AUTH_SIGN_UP_CONFIRM';
export const AUTH_CREATE_ACCOUNT = 'AUTH_CREATE_ACCOUNT';
export const AUTH_USER_DELETE = 'AUTH_USER_DELETE';

export const AUTH_CURRENT_SESSION = 'AUTH_CURRENT_SESSION';
export const AUTH_CURRENT_SESSION_RESET = 'AUTH_CURRENT_SESSION_RESET';

export const AUTH_CURRENT_USER = 'AUTH_CURRENT_USER';
export const AUTH_CURRENT_USER_RESET = 'AUTH_CURRENT_USER_RESET';
export const AUTH_UPDATE_ATTRS = 'AUTH_UPDATE_ATTRS';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_CONFIRM = 'AUTH_FORGOT_PASSWORD_CONFIRM';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';

export const USER_FIND = 'USER_FIND';
export const USER_LESSON_ACTIVITY_LOG_FINDBYID =
  'USER_LESSON_ACTIVITY_LOG_FINDBYID';
export const USER_FINDBYID = 'USER_FINDBYID';
export const USER_CURRENT_FINDBYID = 'USER_CURRENT_FINDBYID';
export const USER_CREATE = 'USER_CREATE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_DELETE = 'USER_DELETE';

export const ORGANIZATION_FIND = 'ORGANIZATION_FIND';
export const ORGANIZATION_FINDBYID = 'ORGANIZATION_FINDBYID';
export const ORGANIZATION_USER_REPORTS_FINDBYID =
  'ORGANIZATION_USER_REPORTS_FINDBYID';
export const ORGANIZATION_CURRENT_FINDBYID = 'ORGANIZATION_CURRENT_FINDBYID';
export const ORGANIZATION_CREATE = 'ORGANIZATION_CREATE';
export const ORGANIZATION_UPDATE = 'ORGANIZATION_UPDATE';
export const ORGANIZATION_DELETE = 'ORGANIZATION_DELETE';

export const GROUP_FIND = 'GROUP_FIND';
export const GROUP_FINDBYORGID = 'GROUP_FINDBYORGID';
export const GROUP_FINDBYID = 'GROUP_FINDBYID';
export const GROUP_CURRENT_FINDBYID = 'GROUP_CURRENT_FINDBYID';
export const GROUP_CREATE = 'GROUP_CREATE';
export const GROUP_UPDATE = 'GROUP_UPDATE';
export const GROUP_DELETE = 'GROUP_DELETE';

export const ENROLLMENT_FIND = 'ENROLLMENT_FIND';
export const ENROLLMENT_FINDBYID = 'ENROLLMENT_FINDBYID';
export const ENROLLMENT_CURRENT_FINDBYID = 'ENROLLMENT_CURRENT_FINDBYID';
export const ENROLLMENT_CREATE = 'ENROLLMENT_CREATE';
export const ENROLLMENT_UPDATE = 'ENROLLMENT_UPDATE';
export const ENROLLMENT_DELETE = 'ENROLLMENT_DELETE';

export const LICENSE_FIND = 'LICENSE_FIND';
export const LICENSE_FINDBYID = 'LICENSE_FINDBYID';
export const LICENSE_CURRENT_FINDBYID = 'LICENSE_CURRENT_FINDBYID';
export const LICENSE_CREATE = 'LICENSE_CREATE';
export const LICENSE_UPDATE = 'LICENSE_UPDATE';
export const LICENSE_DELETE = 'LICENSE_DELETE';

export const BUNDLE_FIND = 'BUNDLE_FIND';
export const BUNDLE_FIND_IN_GROUP = 'BUNDLE_FIND_IN_GROUP';
export const BUNDLE_FINDBYID = 'BUNDLE_FINDBYID';
export const BUNDLE_CURRENT_FINDBYID = 'BUNDLE_CURRENT_FINDBYID';
export const BUNDLE_CREATE = 'BUNDLE_CREATE';
export const BUNDLE_UPDATE = 'BUNDLE_UPDATE';
export const BUNDLE_DELETE = 'BUNDLE_DELETE';

export const COURSE_FIND = 'COURSE_FIND';
export const COURSE_FIND_IN_GROUP = 'COURSE_FIND_IN_GROUP';
export const COURSE_FINDBYID = 'COURSE_FINDBYID';
export const COURSE_CURRENT_FINDBYID = 'COURSE_CURRENT_FINDBYID';
export const COURSE_CREATE = 'COURSE_CREATE';
export const COURSE_UPDATE = 'COURSE_UPDATE';
export const COURSE_DELETE = 'COURSE_DELETE';

export const MIGRATION_STATUS_FIND = 'MIGRATION_STATUS_FIND';
export const MIGRATION_STATUS_FINDBYID = 'MIGRATION_STATUS_FINDBYID';
export const MIGRATION_STATUS_CURRENT_FINDBYID =
  'MIGRATION_STATUS_CURRENT_FINDBYID';
export const MIGRATION_STATUS_CREATE = 'MIGRATION_STATUS_CREATE';
export const MIGRATION_STATUS_UPDATE = 'MIGRATION_STATUS_UPDATE';
export const MIGRATION_STATUS_DELETE = 'MIGRATION_STATUS_DELETE';

export const UPLOADS_SURVEYS_POST = 'UPLOADS_SURVEYS_POST';
