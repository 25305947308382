import { combineReducers } from 'redux';

import authSignUp from './authSignUp';
import authCompleteNewPassword from './authCompleteNewPassword';
import authSignUpConfirm from './authSignUpConfirm';
import authCreateAccount from './authCreateAccount';
import authUserDelete from './authUserDelete';
import authCurrentUser from './authCurrentUser';
import authCurrentSession from './authCurrentSession';
import authPasswordReducer from './authPassword';
import authChangePassword from './authChangePassword';
import authLogout from './authLogout';
import authUpdateAttributes from './authUpdateAttributes';

import userFind from './userFind';
import userFindById from './userFindById';
import userCurrentFindById from './userCurrentFindById';
import userCreate from './userCreate';
import userUpdate from './userUpdate';
import userDelete from './userDelete';

import organizationFind from './organizationFind';
import organizationFindById from './organizationFindById';
import organizationCreate from './organizationCreate';
import organizationUpdate from './organizationUpdate';
import organizationDelete from './organizationDelete';

import groupFind from './groupFind';
import groupFindByOrgId from './groupFindByOrganizationId';
import groupFindById from './groupFindById';
import groupCreate from './groupCreate';
import groupUpdate from './groupUpdate';
import groupDelete from './groupDelete';

import enrollmentFind from './enrollmentFind';
import enrollmentFindById from './enrollmentFindById';
import enrollmentCreate from './enrollmentCreate';
import enrollmentUpdate from './enrollmentUpdate';
import enrollmentDelete from './enrollmentDelete';

import licenseFind from './licenseFind';
import licenseFindById from './licenseFindById';
import licenseCreate from './licenseCreate';
import licenseUpdate from './licenseUpdate';
import licenseDelete from './licenseDelete';

import bundleFind from './bundleFind';
import bundleFindInGroup from './bundleFindInGroup';
import bundleFindById from './bundleFindById';
import bundleCreate from './bundleCreate';
import bundleUpdate from './bundleUpdate';
import bundleDelete from './bundleDelete';

import courseFind from './courseFind';
import courseFindById from './courseFindById';
import courseFindInGroup from './courseFindInGroup';
import courseCreate from './courseCreate';
import courseUpdate from './courseUpdate';
import courseDelete from './courseDelete';

import migrationStatusFindById from './migrationStatusFindById';
import migrationStatusUpdate from './migrationStatusUpdate';

import organizationUserReportsFindById from './organizationUserReportsFindById';
import userLessonActivityLogFind from './userLessonActivityLogFind';

import uploadSurveys from './uploadSurveys';

export default combineReducers({
  auth: combineReducers({
    signUp: authSignUp,
    completeNewPassword: authCompleteNewPassword,
    signUpConfirm: authSignUpConfirm,
    currentUser: authCurrentUser,
    currentSession: authCurrentSession,
    forgotPassword: authPasswordReducer,
    changePassword: authChangePassword,
    createAccount: authCreateAccount,
    logout: authLogout,
    deleteUser: authUserDelete,
    updateAttributes: authUpdateAttributes
  }),
  user: combineReducers({
    find: userFind,
    findById: userFindById,
    current: userCurrentFindById,
    create: userCreate,
    update: userUpdate,
    delete: userDelete
  }),
  organization: combineReducers({
    find: organizationFind,
    findById: organizationFindById,
    create: organizationCreate,
    update: organizationUpdate,
    delete: organizationDelete
  }),
  group: combineReducers({
    find: groupFind,
    findByOrgId: groupFindByOrgId,
    findById: groupFindById,
    create: groupCreate,
    update: groupUpdate,
    delete: groupDelete
  }),
  enrollment: combineReducers({
    find: enrollmentFind,
    findById: enrollmentFindById,
    create: enrollmentCreate,
    update: enrollmentUpdate,
    delete: enrollmentDelete
  }),
  license: combineReducers({
    find: licenseFind,
    findById: licenseFindById,
    create: licenseCreate,
    update: licenseUpdate,
    delete: licenseDelete
  }),
  bundle: combineReducers({
    find: bundleFind,
    findInGroup: bundleFindInGroup,
    findById: bundleFindById,
    create: bundleCreate,
    update: bundleUpdate,
    delete: bundleDelete
  }),
  course: combineReducers({
    find: courseFind,
    findInGroup: courseFindInGroup,
    findById: courseFindById,
    create: courseCreate,
    update: courseUpdate,
    delete: courseDelete
  }),
  migrationStatus: combineReducers({
    findById: migrationStatusFindById,
    update: migrationStatusUpdate
  }),
  reports: combineReducers({
    findUserReportsById: organizationUserReportsFindById,
    findUserLessonActivityLog: userLessonActivityLogFind
  }),
  uploads: combineReducers({
    surveys: uploadSurveys
  })
});
