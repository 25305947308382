import _isEmpty from 'lodash/isEmpty';

import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAuthenticatedUser } from '../../store/actions/auth';

class Root extends Component {
  componentDidMount = async () => {
    const {
      getAuthUser,
      auth: {
        currentUser: { data: currentUserData }
      }
    } = this.props;

    try {
      if (_isEmpty(currentUserData)) {
        await getAuthUser();
      }
    } catch (err) {
      // do nothing for now. Just falls back to
      // normal trending functionality.
      // throw err;
    }
  };

  render() {
    const { children } = this.props;

    return <>{children}</>;
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  getAuthUser: () => dispatch(getAuthenticatedUser())
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Root);
